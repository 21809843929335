// Import bootstrap react components
import { Container } from "react-bootstrap";
import { useEffect, useState, forwardRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
// Import Framer-motion
import { motion } from "framer-motion";
import styled from "styled-components";
import GreenScale from '../images/Scales/green.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import vol from '../images/Stats/vol.png'
import tvl from '../images/Stats/tvl.png'
import price from '../images/Stats/price.png'
import check from '../images/check.png'

import swap from '../images/Features/swap.png'
import pools from '../images/Features/pools.png'
import farming from '../images/Features/farming.png'
import dao from '../images/Features/dao.png'

import GBlob from '../images/Filled/Green_1.png'

import {GLOBAL_DATA} from './apollo/queries'
import {client} from './apollo/client'

import BigNumber from 'bignumber.js'

import { abbrNum } from "./apollo/helper";

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const Info = forwardRef(function(props, ref) {
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const [tokenPrice, setTokenPrice] = useState("...")
  const [volume, setVolume] = useState("...")
  const [TVL, setTVL] = useState("...")

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {

    client.query({
      query: GLOBAL_DATA(),
      fetchPolicy: 'no-cache',
    }).then(result => {

      if (result?.data) {
        setTVL(abbrNum(new BigNumber(result.data.muteSwitchFactories[0].totalLiquidityUSD).plus(4500000).toFixed(), 1))
        setVolume(abbrNum(BigNumber(result.data.muteSwitchFactories[0].totalVolumeUSD).plus(153000000).toFixed(), 1))
        setTokenPrice(new BigNumber(result.data.mute.derivedETH).times(result.data.bundles[0].ethPrice).toFixed(2))
        /*
        this.state.totalLiqETH = result.data.muteSwitchFactories[0].totalLiquidityETH
        this.state.totalLiqUSD = new BigNumber(result.data.muteSwitchFactories[0].totalLiquidityUSD).plus(5000000).toFixed()
        this.state.totalVolETH = result.data.muteSwitchFactories[0].totalVolumeETH
        this.state.totalVolUSD = result.data.muteSwitchFactories[0].totalVolumeUSD
        this.state.totaltx = result.data.muteSwitchFactories[0].txCount
        this.state.totalPair = result.data.muteSwitchFactories[0].pairCount
        */
      }
      
    })

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }


    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, []);    


  useEffect(() => {
    /*
    const onScroll = () => {
      const position = window.scrollY;
  
      if(position > 1800){
        document.body.style.overflow = "hidden";
      }

    };

    var debounce
    var timerStart = false

    const onWheel = (e) => {
      if (e.deltaY === 0) return;

      if(document.body.style.overflow == "hidden"){
        var timeSwap = 1000
        if (debounce && timerStart == false) {
          clearTimeout(debounce);
          debounce = null;
        }


        if(e.deltaY > 0){
          if(controlledSwiper.activeIndex == 2){
            document.body.style.overflow = "visible"
            return
          }

          if(timerStart == false)
            debounce = setTimeout(() => {
              controlledSwiper.slideNext()
              timerStart = false
            }, 1 * timeSwap);
            timerStart = true

        }
        else{
          if(controlledSwiper.activeIndex == 0)
            document.body.style.overflow = "visible"
          else{
            if(timerStart == false)
            debounce = setTimeout(() => {
              controlledSwiper.slidePrev()
              timerStart = false
            }, 1 * timeSwap);

            timerStart = true
          }

        }
      }
    };


    //window.addEventListener("scroll", onScroll);
    //window.addEventListener('wheel', onWheel);
    */
    return () => {
      //window.removeEventListener('scroll', onScroll)
      //window.removeEventListener('wheel', onWheel)

    }

  }, [controlledSwiper]);    
  

  return (
    <MainContaner  id="features" className="section" ref={ref}>
      <WhiteContainer/>
      <BlurContainer />
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-5 mb-5 mt-5">
        <motion.div
          initial={{ x: windowDimensions.width > 500 ? -200 : 0 }}
          whileInView={{ x: 0 }}
          transition={{ duration: windowDimensions.width > 500 ? 0.8 : 0 }}
          style={{marginRight: 'auto', marginLeft: 'auto', width: '100%'}}
        >
          <p className="gray-90 mb-3">
            <ScaleAccent src={GreenScale}/>
            PROTOCOL
          </p>
          <SubHeader className="black-50 mb-5">
            KOI is a lightning fast DEX, yield, and bond platform built on zkSync.
          </SubHeader>

          <RowMod>
            <ColMod>
            <ContentBox>
              <ContentImage src={price}/>
                <StatText>
                  ${tokenPrice}
                </StatText>
              </ContentBox>
            </ColMod>
            <ColMod>
            <ContentBox>
              <ContentImage src={tvl}/>
              <StatText>
                ${volume}
              </StatText>
              </ContentBox>
            </ColMod>
            <ColMod>
              <ContentBox>
                <ContentImage src={vol}/>
                <StatText>
                  ${TVL}
                </StatText>
              </ContentBox>
            </ColMod>
          </RowMod>
        </motion.div>
      </Container>

      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-5 mb-5 mt-5 w-100">

      <LineSplit/>

      </Container>

      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap pt-5 mb-5 mt-5 w-100">
        <motion.div
          initial={{ x: windowDimensions.width > 500 ? -200 : 0 }}
          whileInView={{ x: 0 }}
          transition={{ duration: windowDimensions.width > 500 ? 0.8 : 0 }}
          style={{marginRight: 'auto', marginLeft: 'auto', width: '100%'}}
        >
          <p className="gray-90 mb-3">
            <ScaleAccent src={GreenScale}/>
            FEATURES
          </p>
          <SubHeader className="black-50 mb-5">
            Automated market maker (AMM) exchange, farming platform, bond platform and more.
          </SubHeader>


          <SwiperMod
          grabCursor={true}
          spaceBetween={50}
          slidesPerView={1}
          onSwiper={setControlledSwiper}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          style={{width: windowDimensions.width > 500 ?  '150%' : '100%'}}
          scrollbar={{ draggable: true }}

          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            998: {
              slidesPerView: 3,
            }
          }}
        >
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
            <FeatureBox>
              <FeatureImage src={swap} alt="img" />
              <FeatureContent>
                <FeatureTitle>Swap</FeatureTitle>
                <FeatureText>
                Koi's swapping mechanism finalizes within 1s and keeps gas costs extremely low. You don't even need ETH in your wallet.
                </FeatureText>
                <div>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  $0.10 - $0.20 gas fees
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Pay gas with any token, no ETH needed
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Access to deep liquidity
                </FeaturePoint>
                </div>
              </FeatureContent>
            
            </FeatureBox>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
          <FeatureBox>
              <FeatureImage src={pools} alt="img" />
              <FeatureContent>
                <FeatureTitle>Pools</FeatureTitle>
                <FeatureText>
                Koi is designed to have a dynamic setup for 
                Liquidity Pools so that both Stable and Normal AMM curves can be utilized.
                </FeatureText>
                <div>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Stable and normal curve range
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Fee accumulation outside of LP position
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Participate in select farming pools
                </FeaturePoint>
                </div>
              </FeatureContent>
            </FeatureBox>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
          <FeatureBox>
              <FeatureImage src={farming} alt="img" />
              <FeatureContent>
                <FeatureTitle>Farming</FeatureTitle>
                <FeatureText>
                Koi farming pools use a fixed APY calculation that allows users to pre-determine the rewards they will earn over a 30 day period.
                </FeatureText>
                <div>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Fixed APY model
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Gauranteed reward payouts
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  No lockup
                </FeaturePoint>
                </div>
              </FeatureContent>
            </FeatureBox>
          </SwiperSlide>
          <SwiperSlide className="py-4 px-3 align-items-start flex-column">
          <FeatureBox>
              <FeatureImage src={dao} alt="img" />
              <FeatureContent>
                <FeatureTitle>ve DAO</FeatureTitle>
                <FeatureText>
                Koi's DAO governnace works by locking up tokens into a vote-escrow NFT. The longer the lock, the more vote shares you earn.
                </FeatureText>
                <div>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Participate in voting
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Access to higher farming APYs
                </FeaturePoint>
                <FeaturePoint>
                  <FeatureCheck src={check}/>
                  Revenue sharing (soon)
                </FeaturePoint>
                </div>
              </FeatureContent>
            </FeatureBox>
          </SwiperSlide>
          {
            windowDimensions.width > 500 ?
            (<SwiperSlide className="py-4 px-3 align-items-start flex-column"/>)
            : (<></>)
          }
          
        </SwiperMod>

        </motion.div>
      </Container>
    </MainContaner>
  );
})

/*



*/

const SwiperMod = styled(Swiper)`
overflow: visible
`
const BlurContainer = styled.div`
  position: absolute;
  filter: blur(100px);
  background-image: url(${GBlob});
  background-repeat: no-repeat;
  background-size: 150% 150%;
  opacity: 40%;
  top: -10%;
  width: 100%;
  height: 100%;
  z-index: -1;
`
const WhiteContainer = styled.div`
  position: absolute;
  background: #F9F9F9;
  width: 100%;
  height: 100%;
  z-index: -2;
`

const MainContaner = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
  min-height: 1880px;
  justify-content: space-around;
  margin-top: 50px;
`
const ScaleAccent = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;

`
const SubHeader = styled.p`
  margin-top: 0;
  font-size: 32px !important;
  font-family: 'Telegraf';
  max-width: 900px;
`

const StatText = styled.p`
  position: absolute;
  right: 35px;
  bottom: 0;
  color: white;
  font-family: 'Telegraf';
  font-weight: 500;
  font-size: 42px;

`

const ContentBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 24px;
  height: auto;
  width: auto;
  min-width: 350px;
  max-width: 500px; 

  border: 1px solid white;
  border-radius: 18px;
  padding: 8px;

  @media (max-width: 1000px) {
    min-width: 300px; 
  }
`

const FeatureBox = styled.div`
  border-radius: 24px;
  background: rgba(255,255,255,1);
  border: 1px solid var(--color-light-gray);
  border-radius: 32px;
  height: 593px;
  width: 508px;
  overflow: hidden;

  @media (max-width: 500px) {
    width: 90vw;
    height: 650px;
  }
`

const FeatureTitle = styled.p`
  color: var(--color-black);
  font-size: 32px;
  font-family: 'Telegraf';
  font-weight: 500;
  padding: 20px 40px 0px 40px;

`

const FeatureContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FeatureText = styled.p`
  color: var(--color-gray);
  font-size: 16px;
  font-family: 'DM Sans';
  font-weight: 400;
  padding: 0px 40px 0px 40px;
`

const FeaturePoint = styled.p`
  display: flex;
  align-items: center;
  color: var(--color-black);
  font-size: 16px;
  font-family: 'DM Sans';
  font-weight: 500;
  padding: 0px 40px 0px 40px;
`

const FeatureCheck = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 10px;
`

const FeatureImage = styled.img`
  height: 47.3% !important;

  @media (max-width: 500px) {
    width: 100%;
    height: auto !important;
  }
`

const ContentImage = styled.img`
  width: 100%;
  border: 1px solid var(--color-light-gray);
  border-radius: 26px;

  @media (max-width: 750px) {
    border-radius: 30px;

  }
`

const LineSplit = styled.div`
  border-top: 1px solid var(--color-gray);
  opacity: 30%;
  width: 100%;
`

const RowMod = styled(Row)`
  padding-left: 10px;
  padding-right: 10px;

  background: rgba(255,255,255,1) !important;
  border-radius: 32px;
  border: 1px solid white;
`

const ColMod = styled(Col)`
margin: 0;
padding: 0;
`

export default Info;
