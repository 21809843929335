import {motion} from "framer-motion"
import styled from "styled-components";

function AnimationTitles({title, className, green}) {
  const hVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const spanVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const textObject = {}

  title.split("").map((char, index) => (
      textObject[index] = false
  ))

  if(green) {
    textObject[0] = true
    textObject[1] = true
    textObject[2] = true
    textObject[3] = true
    textObject[4] = true
    textObject[5] = true
    textObject[6] = true
    textObject[7] = true
  }

  return(
    <motion.h1
    variants={hVariants}
    initial="hidden"
    whileInView="visible"
    className={className}
  >
    {title.split("").map((char, index) => (
      (textObject[index] ? 
        <LetterGreen variants={spanVariants} key={index}>
        {char}
      </LetterGreen>
        :
        <Letter variants={spanVariants} key={index}>
        {char}
      </Letter>
        )

    ))}
  </motion.h1>
  )
}


const Letter = styled(motion.span)`
  color: var(--color-black);
  font-family: 'Telegraf';
  font-weight: 500;
  font-size: 72px;
  line-height: 90%;
`
const LetterGreen = styled(Letter)`
  color: var(--color-green);
`

export default AnimationTitles