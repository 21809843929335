const BigNumber = require('bignumber.js')

export function formatNumber(num, digits) {
  const newNum = new BigNumber(num)

  if(newNum.lt(1)){
    return newNum.toPrecision(3)
  }

  return newNum.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function abbrNum(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = ["K", "M", "B", "T"];

  var final = formatNumber(number, 3)

  // Go through the array backwards, so we do the largest first

  if(new BigNumber(decPlaces).gt(number)){
    let abb = final.charAt(final.length - 1)
    return final.substring(0, final.length - 2) + abb
  } else {
    for (var i = abbrev.length - 1; i >= 0; i--) {

      // Convert array index to "1000", "1000000", etc
      var size = Math.pow(10, (i + 1) * 3);
  
      // If the number is bigger or equal do the abbreviation
      if (size <= number) {
        // Here, we multiply by decPlaces, round, and then divide by decPlaces.
        // This gives us nice rounding to a particular decimal place.
        number = Math.round(number * decPlaces / size) / decPlaces;
  
        // Handle special case where we round up to the next abbreviation
        if ((number === 1000) && (i < abbrev.length - 1)) {
          number = 1;
          i++;
        }
  
        // Add the letter for the abbreviation
        final = formatNumber(number, 3) + abbrev[i];
  
        // We are done... stop
        break;
      }
    }
  }

  let abb = final.charAt(final.length - 1)

  return final.substring(0, final.length - 2) + abb
}