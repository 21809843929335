import { Button, Container } from "react-bootstrap";
import { useState, useEffect } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import styled from "styled-components";
import { Parallax } from 'react-scroll-parallax';
import scroll_btn from '../images/scroll_btn.png'


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const scroll = () => {
  const section = document.querySelector( '#features' );
  section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

function Loading() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="home" className="section">
    <Parallax speed={windowDimensions.width < 1000 ? 0 : -50}>
    <HeaderContainer className="loading position-relative">

      <Container className="d-flex justify-content-center text-align-center align-items-center gap-md-5 flex-column flex-md-row mt-3 mt-xl-4 overflow-hidden">
        <HeaderBox
          initial={{ x: -400 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="text-align-center"
        >

        <p className="gray-90 mt-3 fs-5">
            A next generation DAO governed  
          </p>
          <AnimationTitles title="ZKROLLUP" green={true} />
          <AnimationTitles title="DEFI PLATFORM" green={false} />

          <PaddedText className="mt-3 fs-5">
          Trade, earn yields, and participate in Bonds all on one 
          decentralized, community driven platform.
          </PaddedText>
          <ButtonContainer>
          <ButtonMod onClick={() => window.open('https://app.koi.finance', '_blank')} className="m-0 my-3 px-4 py-2 me-2 fs-5 fw-bold ">Enter app</ButtonMod>
          <ButtonMod onClick={() => window.open('https://wiki.mute.io', '_blank')} className="btn-secondary m-0 my-3 px-4 py-2 me-2 fs-5 fw-bold ">Read docs</ButtonMod>
          </ButtonContainer>
          <ScrollButton onClick={() => scroll()}>
            <img alt="scrollbtn"  src={scroll_btn} style={{width: '100%', height: '100%'}}/>
          </ScrollButton>
        </HeaderBox>
      </Container>
    </HeaderContainer>
    </Parallax>
    </div>
  );
}

const HeaderBox = styled(motion.div)`
  text-align: left;
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: -50px !important;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: auto !important;
  @media (max-width: 1000px) {
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-between !important;

    &.btn-primary {
      width: 100% !important;
    }

  }
`

const ButtonMod = styled(Button)`
  @media (max-width: 1000px) {
    width: 40% !important;

  }
`

const HeaderContainer = styled.div` 
  position: relative;
  margin-top: 100px;
  margin-bottom: auto;
  display: flex;
  align-items: flex-start;
  height: 80vh;
  min-height: 800px;

  @media (max-width: 768px) {
    margin-top: 0px !important;
    min-height: 500px !important;
  }

`

const PaddedText = styled.p`
  margin-right: 20%;
  color: var(--color-dark-gray);
`

const ScrollButton = styled.button`
  height: 40px !important;
  width: 170px !important;
  position: absolute;
  bottom: 3%;
  border: 0;
  background: transparent;

  .img {
    height: 40px !important;
    width: 170px !important;
  }

  @media (max-width: 500px) {
    height: calc(0.75 * 40px) !important;
    width: calc(0.75 * 170px)  !important;

    .img {
      height: calc(0.75 * 40px) !important;
      width: calc(0.75 * 170px)  !important;
    }
  }
`

export default Loading;
