import { Button, Container } from "react-bootstrap";
import styled from "styled-components";
import GreenScale from '../images/Scales/green.png'
import KOI3D from '../images/koi3d.png'
import { Parallax } from "react-scroll-parallax";
import { useState, useEffect } from 'react';
import koiicon from '../images/Avatars/white.png'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function Token() {

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Parallax speed={windowDimensions.width < 1000 ? 0 : -50}>
    <div className="subscribe section">
      <ContainerCustom>
        <Content>
          <p className="gray-90 mb-3">
            <ScaleAccent src={GreenScale}/>
                KOI TOKEN
            </p>
            <SubHeader className="black-50 mb-5">
              ve and DAO powers. 
            </SubHeader>
            <SubText className="black-50 mb-5">
            Lock up your KOI tokens to participate in the ve DAO that receives DAO rights, enhanced farming APYs, revenue sharing, and more.
            </SubText>
            <ButtonContainer>
            <ButtonPrimary onClick={() => window.open('https://koi.finance', '_blank')} className="m-0 my-3 px-4 py-2 me-2 fs-5 fw-bold">
              <KoiToken src={koiicon} onClick={() => window.open('https://app.koi.finance', '_blank')}/>
              Buy KOI
              </ButtonPrimary>
              <ButtonMod onClick={() => window.open('https://wiki.mute.io/mute/info/tokenomics-1', '_blank')} className="btn-secondary m-0 my-3 px-4 py-2 me-2 fs-5 fw-bold">Tokenomics</ButtonMod>
            </ButtonContainer>

        </Content>
          <TokenIcon src={KOI3D}/>

      </ContainerCustom>
    </div>
    </Parallax>
  );
}

const ContainerCustom = styled(Container)`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  height: fit-content;

  @media (max-width: 1000px) {
    flex-direction: column;
    flex-direction: column-reverse;
    padding: 40px !important;

  }
`

const KoiToken = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`
const ButtonPrimary = styled(Button)`
  align-items: center;
  justify-content: center;
  display: flex;
  color: var(--color-dark-green);
  background: var(--color-light-green);
  border: 1px solild var(--color-light-green);

  &:hover {
    background: var(--color-dark-green);
    color: var(--color-light-green);
    border: 1px solild var(--color-dark-green);
  
  }
`

const ButtonMod = styled(Button)`
  border-color: #DFDFDF;
`

const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
width: auto !important;
@media (max-width: 1000px) {
  flex-direction: column;
  width: 100% !important;

}
`

const Content = styled.div`

@media (max-width: 1000px) {
  width: 100% !important;

}

`

const TokenIcon = styled.img`
  width: 414px;
  height: 401px;
  display: flex;
  margin-left: auto;

  @media (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 500px) {
    margin-left: auto;
    margin-right: auto;
    width: calc(414px * 0.7);
    height: calc(401px * 0.7);
  }

`

const ScaleAccent = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;

`
const SubHeader = styled.p`
  margin-top: 0;
  font-size: 32px !important;
  font-family: 'Telegraf';
`

const SubText = styled.p`
  margin-top: 0;
  font-size: 18px !important;
  font-family: 'DM Sans';
  color: var(--color-gray);
`

export default Token;
