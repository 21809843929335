import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {ReactComponent as Logo} from "../../images/logo/Logo.svg";
import styled from 'styled-components'

import { Button } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";

function NavBar() {
  return (
    <NavBarParent expand="lg" className="py-3">
      <NavBarChild>
        <Navbar.Brand href="#" className="me-lg-5">
          <Logo className="logo" alt="logo" />
        </Navbar.Brand>
        <NavBarMenu aria-controls="navbarScroll" />

        <NavBarMain id="navbarScroll" is-nav>
          <NavContainer className="me-auto my-2 my-lg-0 w-100" navbarScroll>
            <NavLink href="#home">Home</NavLink>
            <Divider/>
            <NavLink href="#features"  >Features</NavLink>
            <Divider/>
            <NavLink href="#token">Token</NavLink>
            <Divider/>
            <NavLink href="https://wiki.mute.io" target="_blank">Docs</NavLink>
          </NavContainer>
        </NavBarMain>
        <AppButton className="d-flex align-items-center order">
          <ButtonMain onClick={() => window.open('https://app.koi.finance', '_blank')} className="btn-primary">
            Enter app
          </ButtonMain>
        </AppButton>
      </NavBarChild>
    </NavBarParent>
  );
}

const NavBarParent = styled(Navbar)`
  z-index: 5;
  position: fixed;
  width: -webkit-fill-available;
  top: 13px;
  margin-right: 26px;
  margin-left: 26px;
  border: 1px solid transparent;
  border-radius: 24px;
  background-color: rgba(255,255,255, 0.5);
  height: 94px;
  padding: 0 !important;

  @media (max-width: 1000px) {
    margin: 0;
    top: 0;
    border-radius: 0;
    height: 78px;

  }

`
const NavBarChild= styled(Container)`
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: none;
  border: 1px solid white;
  border-radius: 16px;
  padding: 10px 30px 10px 30px;
  margin: 10px;
  height: 74px;
  background: white;

  @media (max-width: 1000px) {
    margin: 0;
    margin: 0;
    top: -6px;
    margin-bottom: 0px;
    border-radius: 0;
  }
`

const NavBarMenu = styled(Navbar.Toggle)`

  @media (max-width: 1000px) {
    order: 5;
  }

`

const NavContainer = styled(Nav)`
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 5;

  @media (max-width: 1000px) {
    height: 100%
  }

`
const Divider = styled.div`
  width: 40px;

  @media (max-width: 1000px) {
    display: block;
    border-top: 1px solid var(--color-dark-gray);
    opacity: 40%;
    width: 50%;
    margin: 3%
  }

`

const NavLink = styled(Nav.Link)`
  font-family: 'Telegraf';
  font-weight: 500;
  color: var(--color-black) !important;

  @media (max-width: 1000px) {
    font-size: 32px;
    color: var(--color-black) !important;
  }

  &.active {
    position: relative;
    color: var(--color-green) !important;
    &:before {
      transform: translateX(-50%);
      border-radius: 100%;
      position: absolute;
      background: var(--color-green);
      bottom: 0px;
      height: 5px;
      content: '';
      width: 5px;
      left: 50%;
    }
  }
`
const NavBarMain = styled(Navbar.Collapse)`
  @media (max-width: 1000px) {
    position: absolute;
    left: 0;
    top: 72px;
    width: 100%;
    background-color: rgba(255,255,255, 0.9);
    backdrop-filter: blur(30px);
  }

  
`

const AppButton = styled.div`
  @media (max-width: 1000px) {
    order: 0;
    margin-left: auto;
    margin-right: 10px;
  }
`


const ButtonMain = styled(Button)`
  @media (max-width: 500px) {
    order: 0;
    margin-left: auto;
    margin-right: 10px;
    padding: 7px 20px;
  }
`

export default NavBar;
