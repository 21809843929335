import gql from 'graphql-tag'

export const GLOBAL_DATA = () => {
  const queryString = ` query muteSwitchFactories {
      muteSwitchFactories(first:1){
        id
        totalVolumeUSD
        totalVolumeETH
        untrackedVolumeUSD
        totalLiquidityUSD
        totalLiquidityETH
        txCount
        pairCount
      }

      bundles(first:1){
        ethPrice
      }
      mute:token(id:"0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42"){
        derivedETH
      }
      koi:token(id:"0xa995ad25ce5eb76972ab356168f5e1d9257e4d05"){
        derivedETH
      }
    }`
  return gql(queryString)
}