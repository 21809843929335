import { BrowserRouter } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import NavBar from "./components/navbar/Navbar";
import Footer from "./pages/Footer";
import Loading from "./pages/Header";
import Info from "./pages/Info";
import Token from "./pages/Token";
import styled from "styled-components";
import bgVid from './images/video/hero_slow.mp4'
import banner from './images/banner.png'
import koitoken from './images/Avatars/koi.png'

import BigNumber from 'bignumber.js'


import { ParallaxProvider } from 'react-scroll-parallax';

function App() {
  const [useWidth, setUseWidth] = useState(false)
  const videoRef = useRef(null);
  const listInnerRef = useRef(null);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { clientHeight } = listInnerRef.current;
      const position = window.scrollY;

      const isNearBottom = position >= (clientHeight * 0.5 * 0.75);
      if (isNearBottom) {
       // videoRef.current.pause()
        //console.log("Reached bottom");
      }
    }
  };

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      var dim = { width, height };
      setUseWidth(new BigNumber(dim.width).div(dim.height).gte(new BigNumber(2560).div(1440)))
    }

    const listInnerElement = listInnerRef.current;
    window.addEventListener("scroll", onScroll);
    window.addEventListener('resize', handleResize);

    handleResize()

    // Clean-up
    return () => {
      window.removeEventListener('resize', handleResize);
      listInnerElement.removeEventListener("scroll", onScroll);
    };
  }, []);    

  return (
    <>
    <ParallaxProvider>
      <BrowserRouter>
        <img style={{display: 'none'}} alt="banner" src={banner}/>
        <img style={{display: 'none'}} alt="token" src={koitoken}/>

        <BgVideo
          id="video-player"
          className="video"
          src={bgVid}
          autoPlay={true}
          loop={true}
          ref={videoRef}
          muted={true}
          playsInline={true}
          style={{width: useWidth ? "100%" : "auto", height: useWidth ? "auto" : "100%"}}
        />
        <NavBar />
        <Loading />
        <Info ref={listInnerRef}/>
        <Token />
        <Footer />
      </BrowserRouter>
      </ParallaxProvider>
    </>
  );
}

const BgVideo = styled.video`
  position: fixed;
  top: 0px;
  z-index: -1;
`




export default App;
