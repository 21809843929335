import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import styled from "styled-components";

import GrayLogo from '../images/logo/gray.png'
import { faXTwitter, faDiscord, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Footer() {
  return (
    <footer className="section">
      <Container id="token">
      <TopLine/>

        <ContentParent className="d-flex justify-content-between pt-5 pb-5">
          <motion.div
            initial={{ x: -200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            style={{justifyContent: 'center', width: '100%', alignItems: 'flex-end'}}

          >
            <Logo alt="logo" src={GrayLogo} />
          </motion.div>
          <motion.div
            initial={{ x: 200 }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="d-flex flex-column"
            style={{justifyContent: 'center', width: '100%', alignItems: 'flex-end'}}
          >
            <ContentBlock className="me-0 d-flex flex-row">

              <SocialIcon icon={faTelegram} onClick={() => window.open('https://t.me/mute_io', '_blank')}/>
              <SocialIcon icon={faXTwitter} onClick={() => window.open('https://x.com/mute_io', '_blank')}/>
              <SocialIcon icon={faDiscord} onClick={() => window.open('https://discord.gg/muteio', '_blank')}/>
              <Copyright>
                © 2024 KOI.FINANCE
              </Copyright>
            </ContentBlock>
          </motion.div>
        </ContentParent>
      </Container>
    </footer>
  );
}

const TopLine = styled.div`
  border-top: 1px solid var(--color-dark-gray);
  opacity: 40%;
`

const SocialIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: var(--color-gray);
  height: 25px;
  margin-right: 15px;
`

const ContentBlock = styled.div`

  @media (max-width: 765px) {
    width: 100%;
    justify-content: flex-start
  }

`

const ContentParent = styled.div`
flex-direction: row !important;

  @media (max-width: 765px) {
    flex-direction: column !important;
    width: 100%;
  }

`

const Copyright = styled.p`
  font-family: 'Telegraf';
  color: var(--color-gray);

  @media (max-width: 765px) {
    margin-left: auto;
  }

`

const Logo = styled.img`
  width: 99px;
  height: 32px;

  @media (max-width: 765px) {
    margin-left: auto;
    justify-content: flex-end;
  }

`

export default Footer;
